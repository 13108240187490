import axios from "axios";

axios.defaults.headers["Content-Type"] =
  "application/x-www-form-urlencoded; charset=UTF-8";
// // 创建axios实例 "
const service = axios.create({
  baseURL: "https://rmq.qrongtong.com/api/v1",
  timeout: 10000,
});
// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "bearer " + localStorage.getItem("token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    if (response.data.code == 401) {
      localStorage.setItem("locationHref", window.location.href);
      window.location.href = `/login`;
    }
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
//get请求
const $get = (url, param) => {
  // param.token = localStorage.getItem("token");
  return new Promise((resolve) => {
    service({
        method: "get",
        url,
        params: param,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err, "异常");
      });
  });
};
//post请求
const $post = (url, param) => {
  return new Promise((resolve) => {
    service({
        method: "post",
        url,
        data: param,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err, "异常");
      });
  });
};

//upload上传
const $upload = (data) => {
  // 创建一个 FormData 对象来传递文件
  const formData = new FormData();
  formData.append("file", data.file);
  // 假设你的文件在 data 对象的 file 属性中
  return new Promise((resolve) => {
    service({
        method: "post",
        url: "/user/upload_image",
        data: formData,
        // 设置请求头，告诉服务器这是一个文件上传请求
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err, "异常");
      });
  });
};

export {
  service,
  $get,
  $post,
  $upload
};