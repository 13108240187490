import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    redirect: "index",
  },
  {
    path: "/index",
    name: "index",
    redirect: "/index/list",
    component: () => import("@/views/index/index.vue"),
    children: [{
        path: "list",
        component: () => import("@/views/index/list.vue"),
      },
      {
        path: "detail",
        component: () => import("@/views/index/detail.vue"),
      },
    ],
  },
  {
    path: "/order",
    name: "order",
    redirect: "/order/list",
    component: () => import("@/views/order/index.vue"),
    children: [{
        path: "list",
        component: () => import("@/views/order/list.vue"),
      },
      {
        path: "release",
        component: () => import("@/views/order/release.vue"),
      },
    ],
  },
  {
    path: "/demand",
    name: "demand",
    redirect: "/demand/list",
    component: () => import("@/views/demand/index.vue"),
    children: [{
        path: "list",
        component: () => import("@/views/demand/list.vue"),
      },
      {
        path: "release",
        component: () => import("@/views/demand/release.vue"),
      },
    ],
  },
  {
    path: "/user",
    name: "user",
    redirect: "/user/user",
    component: () => import("@/views/user/index.vue"),
    children: [{
        path: "user",
        component: () => import("@/views/user/user.vue"),
      },
      {
        path: "info",
        component: () => import("@/views/user/info.vue"),
      },
      {
        path: "choose",
        component: () => import("@/views/user/choose.vue"),
      },
      {
        path: "auth",
        component: () => import("@/views/user/auth.vue"),
      },
      {
        path: "feedback",
        component: () => import("@/views/user/feedback.vue"),
      },
      {
        path: "feedlist",
        component: () => import("@/views/user/feedlist.vue"),
      },
      {
        path: "card",
        component: () => import("@/views/user/card.vue"),
      }, {
        path: "list",
        component: () => import("@/views/user/list.vue"),
      },
      {
        path: "agreement",
        component: () => import("@/views/user/agreement.vue"),
      },
      {
        path: "password",
        component: () => import("@/views/user/password.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/phone",
    name: "phone",
    component: () => import("@/views/login/phone.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/login/register.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;