<template>
  <div>
    <div class="navbar">
      <van-icon v-if="showLeft" class="goBack" name="arrow-left" size="22" @click="goBack" />
      <div class="title">{{ title }}</div>
    </div>
    <div class="seat"></div>
  </div>
</template>

<script>
export default {
  name: 'navBar',
  props: {
    title: { type: String },
    showLeft: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
$height: 120px;
.seat {
  height: $height;
}
.navbar {
  background: url('@/assets/navbar-bg.png') center center no-repeat;
  background-color: #fff;
  background-size: 100%;
  height: $height;
  line-height: $height;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 34px;
  z-index: 10;
}
.goBack {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}
</style>

